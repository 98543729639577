import Button from 'components/common/Button';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PanelChoice from './PanelChoice';
import TextInput from './TextInput';
import DropdownMultiple from './DropdownMultiple';
import DropdownSingle from './DropdownSingle';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {encodeDataToURL, capitalize, utilsClosePopup} from 'utils';
import ListInputDescription from './ListInputDescription';
import ListInput from './ListInput';
import YesNoInput from './YesNoInput';
import FileInput from './FileInput';
import ReactLoading from 'react-loading';
import RecordingPanel from './RecordingPanel';
import RecordingSummaryPanel from './RecordingSummaryPanel';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';
import * as Cookies from 'js-cookie';
import DropdownMultipleCreatable from './DropdownMultipleCreatable';

export class PanelContent extends Component {

  getDescription(panel) {
    return <p>{this.props.t(panel.name)}</p>;
  }

  getDescriptionAndChoice(panel) {
    return (
    <>
    <p className="tw-font-bold tw-text-center">{this.props.t(panel.name + '_onboarding')}</p>
    <p>{this.props.t(panel.name)}</p>
    <Button action={() => {this.props.nextSlide()}} text={this.props.t('common:start')}/>
    <div className="tw-relative tw-my-3">
  <div className="tw-absolute tw-inset-0 tw-flex tw-items-center" aria-hidden="true">
    <div className="tw-w-full tw-border-t tw-border-gray-300"></div>
  </div>
  <div className="tw-relative tw-flex tw-justify-center">
    <span className="tw-px-3 tw-bg-white tw-text-lg tw-font-medium tw-text-gray-900">
      { this.props.t('common:or')}
    </span>
  </div>
</div>

<p>{this.props.t('video_cv_file')}</p>
    <ul className="tw-list-inside tw-list-decimal">
      <li>{this.props.t('first_question')}</li>
      <li>{this.props.t('second_question')}</li>
      <li>{this.props.t('third_question')}</li>
      <li>{this.props.t('fourth_question')}</li>
      <li>{this.props.t('fifth_question')}</li>
    </ul>

    <FileInput key={panel.name} name={panel.name} subtitle={`Formats: avi,mp4,mov,qt`} accepted={'avi,mp4,mov,qt'} required={false} noLabel={true} noTitle={true} video={true} />
    
    <Button action={() => {this.props.submit()}} text={this.props.t('common:next')}/>
        </>
    );

  }

  getNoAuthAffiliate(panel) {
    return (
      <>
        <p className="tw-text-center">{this.props.t('affiliation')}</p>
          <Button action={() => {
            this.props.history.push('/signup/infos');
            Cookies.set('origin', '/affiliation')
            }} class="ml-auto mt-2" text={this.props.t('common:signup')}/>
      </>
    );
  }

  getDescriptionFirst(panel) {
    return (<>
    <p className="tw-text-xl tw-text-center tw-font-semibold">{this.props.t(panel.name + 'First')}</p>
    <p className="tw-text-center">{this.props.t(panel.name)}</p>
    </>);
  }


  getInputText(panel) {
    if(panel.inputType == 'email') {
      return (
        <>
          <TextInput key={panel.name} noLabel={true} type="email" name={panel.name} />
        </>
      );
    }
    if(panel.inputType == 'password') {
      return (
        <>
          <TextInput key={panel.name} noLabel={true} type="password" name={panel.name} />
        </>
      );
    }
    return (
      <>
        <TextInput key={panel.name} noLabel={true} name={panel.name} />
      </>
    );
  }

  getType(panel) {
    return (
      <PanelChoice key={panel.name} setPath={this.props.setPath} />
    )
  }

  getDropdown(panel) {
    return (
      <>
      <DropdownSingle noLabel={true} key={panel.name} name={panel.name} options={this.props.data[panel.options]} />
      </>
    );
  }

  getDropdownMultipleTags(panel) {
    return (
      <>
      <DropdownMultipleCreatable noLabel={true} key={panel.name} tags={true} required="false" name={panel.name} options={this.props.data[panel.options]} />
      </>
    );
  }


  getDropdownMultiple(panel) {
    return (
      <>
      <DropdownMultiple noLabel={true} key={panel.name} required="false" name={panel.name} options={this.props.data[panel.options]} />
      </>
    );
  }



  getRecording(panel) {
      return (
      <RecordingPanel key={panel.name} name={panel.name} />
    )
  }

  getRecordingSummary(panel) {
    return (
      <RecordingSummaryPanel key={panel.name} name={panel.name} />
    )

  }


  getPath(panel) {
    if(this.props.path == 'company') {
      window.location.href = process.env.REACT_APP_BUSINESS_URL  + '/subscription-signup?' + encodeDataToURL(this.props.form); 
    } else {
      return this.props.nextSlide();
    }
    return <div></div>;
  }

  getListDescription(panel) {
    return (
      <>
      <ListInputDescription key={panel.name} noLabel={true} name={panel.name} options={this.props.data[panel.options]} />
      </>
    );
  }

  getList(panel) {
    return (
      <>
        <ListInput key={panel.name} noLabel={true} name={panel.name} />
      </>
    );
  }

  getYesNo(panel) {
    return ( 
      <YesNoInput key={panel.name} noLabel={true} whiteText={true} name={panel.name} />
    );
  }

  getFile(panel) {
    var accepted = panel.accepted.split(',').join(', ');
    return ( 
      <>
        <FileInput key={panel.name} name={panel.name} subtitle={`Formats: ${accepted}`} accepted={panel.accepted} required={false} noLabel={true} noTitle={true} />
      </>
    );
  }

  getOnboarding() {
    return (
      <>
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="tw-text-center tw-text-lg tw-leading-6 tw-font-medium tw-text-gray-900" id="modal-title">
               {this.props.t('cvChances')}
            </h3>
          </div>
        </div>
        <div class={`tw-mt-5 sm:tw-mt-6 ${(this.props.popupState.data.cvDone == true || this.props.popupState.data.videoCvDone == true) ? "tw-flex tw-flex-row tw-justify-center" : "sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-3 sm:tw-grid-flow-row-dense"}`}>
          {this.props.popupState.data.cvDone == false &&  <Button text={this.props.t('fillCV')} action={() => {this.props.history.push('/cv')}} />}
          {this.props.popupState.data.videoCvDone == false && <Button text={this.props.t('fillVideoCV')} action={() => {this.props.history.push('/video-cv')}} />}
        </div>
      </>
    )
  }

  getCvChoice() {
    return (
      <>
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="tw-text-center tw-text-lg tw-leading-6 tw-font-medium tw-text-white" id="modal-title">
               {this.props.t('cvChoice')}
            </h3>
          </div>
        </div>
        <div class={`tw-mt-4 sm:tw-mt-6 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-3 sm:tw-grid-flow-row-dense`}>
          <Button text={this.props.t('uploadCV')} action={() => {this.props.setSlide(1)}} />
          <Button text={this.props.t('createCV')} action={() => {this.props.setSlide(2)}} />
        </div>
      </>
    )
  }

  getVideoCvChoice() {
    return (
      <>
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="tw-text-center tw-text-lg tw-leading-6 tw-font-medium tw-text-white" id="modal-title">
               {this.props.t('video_cv_onboarding')}
            </h3>
          </div>
        </div>
        <div class={`tw-mt-4 sm:tw-mt-6 sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-3 sm:tw-grid-flow-row-dense`}>
          <Button text={this.props.t('uploadVideoCV')} action={() => {this.props.setSlide(1)}} />
          <Button text={this.props.t('createVideoCV')} action={() => {this.props.setSlide(2)}} />
        </div>
      </>

    );
  }


  getExplanations() {
    return (
      <>
        <div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="tw-text-center tw-text-lg tw-leading-6 tw-text-gray-900" id="modal-title">
               {this.props.t('explanations')}
            </h3>
          </div>
        </div>
        <div class="tw-mt-5 sm:tw-mt-6 tw-flex tw-flex-row tw-justify-center">
          <Button text={this.props.t('backHome')} action={() => {utilsClosePopup();}} />
        </div>
      </>
    )
  }


  getDescriptionFile(panel) {
    var accepted = panel.accepted.split(',').join(', ');
    return ( 
      <div>
        {this.getLabel({name: panel.name + '_file'})}
        <ul className="tw-list-disc tw-pl-6">
        <li>{this.props.t('first_question')}</li>
        <li>{this.props.t('second_question')}</li>
        <li>{this.props.t('third_question')}</li>
        <li>{this.props.t('fourth_question')}</li>
        <li>{this.props.t('fifth_question')}</li>
        </ul>
        <FileInput key={panel.name} name={panel.name} subtitle={`Formats: ${accepted}`} accepted={panel.accepted} required={false} noLabel={true} noTitle={true} />
      </div>
    );
  }

  getSubmit(panel) {
    this.props.submit();
    return <div className="tw-flex tw-flex-row tw-justify-center tw-items-center">
          <ReactLoading type="bubbles" color="#f5ff00" />
      </div>;
  }

  previousButton(panel) {
    if(this.props.current != 0 && panel?.type !== 'submit') {
      return <Button tabIndex="0" action={() => {this.props.previousSlide()}} text={this.props.t('common:previous')}/>;
    }
    return <div></div>;
  }

  nextButton(panel) {
    if(this.props.current != this.props.maxSlides - 1 && panel?.type !== 'type') {
      return <Button action={() => {this.props.nextSlide()}} text={this.props.current == 0 && (this.props.type == 'video' || this.props.type == 'signup') ? this.props.t('common:start') : this.props.t('common:next')}/>;
    }
    return <div></div>;
  }

  getStyles() {
  }

  getLabel(panel) {
    if(panel.required === true) {
      return <label className="tw-mb-1 tw-mt-3">{this.props.t(panel.name)} <span className="tw-text-sm tw-text-aa-yellow">{this.props.t('input:required')}</span></label>
    }
    return <label className="tw-mb-1 tw-mt-3">{this.props.t(panel.name)}</label>
  }

  getClasses(panel = null) {
    var initial = 'tw-w-11/12 tw-text-lg';
    if(this.props?.popup != true) {
      initial = initial +  ' md:tw-w-1/2';
    } else {
      initial = initial +  ' md:tw-w-10/12 ';
    }
    if(panel?.type == 'description') {
      initial = initial + ' tw-text-center ';
    } else {
      initial = initial + ' tw-text-left ';
    }
    if(Array.isArray(panel) && panel[0]?.type == 'yesNo') {
      initial = initial + " tw-grid tw-grid-cols-2";
    }
    if(this.props?.popup != true) {
      if(this.props?.fillPage == true) {
        initial = initial + " panel-fill";
      } else {
        initial = initial + " panel-signup"
      }
    }

    return initial;
  }

  getPanelContent(panel) {
    if(Array.isArray(panel)) {
      return (
      <div class={this.getClasses()} style={this.getStyles()}>
        <div class={(Array.isArray(panel) && panel[0]?.type == 'yesNo') ? 'tw-grid tw-grid-cols-1 md:tw-grid-cols-2' : ''}>
      {panel.map(p => {
        if(Array.isArray(p)) {
          return (<div className="tw-grid md:tw-grid-cols-2 tw-grid-cols-1">
            {p.map(choice => (
              <div>
                {this.getLabel(choice)}
                {this.['get' + capitalize(choice.type)](choice)}
              </div>
            ))}
            </div>);
        }
        if(p.type == null) {
          p.type = "inputText";
        }
        return (<div>
          {!['type', 'submit', 'path', 'description', 'descriptionFirst', 'descriptionFile', 'onboarding', 'explanations', 'noAuthAffiliate', 'cvChoice', 'videoCvChoice'].includes(p.type) && this.getLabel(p)}
          {this.['get' + capitalize(p.type)](p)}
        </div>);
      })}
      </div>
        <div className="tw-flex tw-flex-row tw-justify-between tw-mt-3">
          {!panel.noPrevious && this.previousButton(panel)}
          {!panel.noNext && this.nextButton(panel)}
        </div>
      </div>
      );
    }
    if(panel.type == null) {
      panel.type = "inputText";
    }
    return (
      <div class={this.getClasses(panel)} style={this.getStyles()}>
          {!['type', 'submit', 'path', 'description', 'descriptionFirst', 'descriptionFile', 'descriptionAndChoice', 'onboarding', 'explanations', 'noAuthAffiliate', 'cvChoice', 'videoCvChoice'].includes(panel.type) && this.getLabel(panel)}
          {this.['get' + capitalize(panel.type)](panel)}
        <div className="tw-flex tw-flex-row tw-justify-between tw-mt-3">
          {this.previousButton(panel)}
          {(panel.type != 'descriptionAndChoice' && this.props.onlyShow != true && !panel.noNext) && this.nextButton(panel)}
        </div>
      </div>
    )
  }
  

  // eslint-disable-next-line react/require-render-return
  render () {
    var {t, panel} = this.props;
    if(!panel) {
      return null;
    }
    return this.getPanelContent(panel);
  }
}

const mapStateToProps = ({ input }) => {
  return {
    form: input.signup,
    popupForm: input.popup
  };
};


export default withRouter(connect(mapStateToProps)(withTranslation(['signup', 'common', 'input'])(PanelContent)));
