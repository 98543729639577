import CompanyBox from 'components/jobOffers/CompanyBox';
import JobBox from 'components/jobOffers/JobBox';
import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import celebrating from 'img/celebrating.svg';
import Jobbox from './Jobbox';
import * as Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import OldJobOffers from 'components/jobOffers/OldJobOffers';
import { utilsOpenPopup } from 'utils';

export class JobOffers extends Component {
  constructor () {
    super();
    this.state = { loading: true, jobs: [], componentsLoading: false, popupDisplay: false };
    this.sendInterest = this.sendInterest.bind(this);
  }

  componentDidMount () {
    instance.get('/my-jobs').then((s) => {
      this.setState({ loading: false, jobs: s.data.jobs, jobbox: s.data.jobbox });
    });
  }

  sendInterest (interested) {
    var fd = new FormData();
    fd.append('job', this.state.jobs[0].id);
    fd.append('interested', interested);
    this.setState({ componentsLoading: true });
    instance.post('/my-jobs/interest', fd).then((s) => {
      this.setState({ jobs: this.state.jobs.slice(1), componentsLoading: false });
    }).catch((e) => {
      if(e.response.data.action == 'complete-profile') {
        Cookies.set('popup-origin', "/job-offer/" + this.state.jobs[0].id, { expires: 1 / 48 });
        this.setState({ jobs: this.state.jobs.slice(1), componentsLoading: false });
        utilsOpenPopup('onboarding');
      }
    });;
  }



  render () {
    // return <Jobbox />;
    if (this.state.jobs.length !== 0) {
      const job = this.state.jobs[0];
      const { employer } = job;
      return (
        <Screen loading={this.state.loading} type="video" title={this.props.t('pages:jobs')} padding={false}>
            <OldJobOffers />
          <div className="container">
            <div className="row">
              <CompanyBox loading={this.state.componentsLoading} employer={employer}/>
              <JobBox loading={this.state.componentsLoading} sendInterest={this.sendInterest} job={job} />
            </div>
          </div>
        </Screen>
      );
    }
    return (
      <Screen loading={this.state.loading} containerClasses="tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center" type="video" title={this.props.t('pages:jobs')}>
        <div style={{ width: '500px', position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} className="tw-flex tw-flex-col tw-justify-center tw-space-y-4 tw-items-center tw-text-center tw-card tw-p-4">
          <img style={{ height: '200px' }} src={celebrating} />
          <p>{this.props.t('noMoreJobs')}</p>
          <p>{this.props.t('editYourProfile')}</p>
        </div>
      </Screen>

    );
  }
}

export default withTranslation('common')(withRouter(JobOffers));
