import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PostComponent from 'components/social/PostComponent';
import instance from 'axiosInstance';
import ReactLoading from 'react-loading';
import { Button } from 'components/common/Button';
import CreatePost from './CreatePost';
import Post from './Post';
import { authOverlay, getAmountInCart, isAuth, notAffiliated, successNotification, utilsOpenPopup, utilsSetStep } from 'utils';
import SearchBar from 'components/social/SearchBar';
import * as Cookies from 'js-cookie';
import SetupPopup from 'components/profile/SetupPopup';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

export class CoffeeMachine extends Component {
  constructor () {
    super();
    this.state = { seed: 0, loading: true, posts: [], pinned: [], likes: [], employers: [], candidates: [], nextLink: 'tbd', done: false, createPostOpened: false, search: '', hashtags: [], cartSize: getAmountInCart(), auto_pinned_ids: [], autocomplete: [], relevant: [], affiliated: false, popup: undefined };
  }

  componentDidMount () {
    var parser = new URLSearchParams(window.location.search)
    if(parser.get('search')) {
      this.setState({loading: true, search: parser.get('search')}, this.getPosts(parser.get('search')));
    } else {
      if(this.props?.location?.state?.search) {
        this.getPosts(this.props.location.state.search);
        this.setState({search: this.props.location.state.search});
      } else {
        this.setState({seed: Math.floor(Math.random() * (1000 - 1 + 1) + 1)}, () => {
          this.getPosts();
        });  
      }  
    }
    if(this.props?.location?.state?.popup == 'onboarding') {
      utilsOpenPopup('onboarding');
    }
    this.getAutocomplete();
  }

  getHashtags() {
    instance.get('/hashtags').then(s => {
      this.setState({hashtags: s.data.hashtags})
    });
  }

  getAutocomplete() {
    instance.get('/autocomplete').then(s => {
      this.setState({autocomplete: s.data.autocomplete})
    });
  }

  getPosts (s = undefined) {
    if(process.env.REACT_APP_BASE_URL == 'http://localhost/api') {
      this.setState({loading: false});
    }
    if(s != undefined && s != '') {
      if(s[0] == '#') {
        s = s.substr(1);
      }
      instance.get('/posts/search?search=' + s).then(s => {
        var originalData = s.data.posts.data;
        if(s.data?.posts?.data?.length) {
          while(s.data.posts.data.length < 40) {
            s.data.posts.data = s.data.posts.data.concat(originalData);
          }
        }
        this.setState({ loading: false, posts: s.data.posts.data, employers: s.data.employers, pinned: [], nextLink: s.data.posts.next_page_url, popupStep: s.data.popup_step, affiliated: s.data.affiliated});
      });
    } else {
      this.getHashtags();
      instance.get('/posts?seed=' + this.state.seed).then(s => {
        this.setState({ loading: false, pinned: s.data.pinned, posts: s.data.posts.data, candidates: s.data.candidates, nextLink: s.data.posts.next_page_url, likes: s.data.likes, types: s.data.types, searchPopup: s.data.searchPopup, auto_pinned_ids: s.data.auto_pinned_ids, popupStep: s.data.popup_step, affiliated: s.data.affiliated, relevant: s.data.relevantPosts });
      });  
    }
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if(!this.state.nextLink) {
        this.setState({done: true});
        this.setState({posts: this.state.posts.concat(this.state.posts)})
      } else {
        const url =  (new URL(this.state.nextLink));
        instance.get(url.pathname.substr(5) + url.search).then(s => {
          this.setState(state => {
            const posts = state.posts.concat(s.data.posts.data);
            return {
              posts,
              nextLink: s.data.posts.next_page_url
            }
          });
        });
      } 
    }
  }

  getLiked(post) {
    var likes = this.state.likes.filter(like => like.post_id == post.id);
    if(likes.length == 0) {
      return false;
    }
    return true;
  }

  concated() {
    const {posts, pinned, employers, candidates, relevant} = this.state;
    if(this.state.search) {
      return posts.concat(employers).sort((a, b) => 0.5 - Math.random());
    }
    var postsN = posts.filter(p => {
      return pinned.filter(pinned => pinned.id == p.id).length == 0;
    })
    var relevantPosts = Object.values(relevant);
    return relevantPosts.concat(pinned).concat(postsN).concat(candidates);
  }

  render () {
    const { t } = this.props;
    const { posts, pinned, loading, hashtags } = this.state;
    return (
      <Screen paddingTop={false} cartSize={this.state.cartSize} overall={true} titleComp={<SearchBar search={this.state.search} items={this.state.autocomplete} newSearch={(s) => {
        this.setState({loading: true, search: s}, this.getPosts(s));
        this.props.history.push('/?search=' + encodeURIComponent(s));
        }} />} loading={loading} type="background-white" handleScroll={this.handleScroll} title={t('pages:coffeeMachine')}>
        <Helmet>
        <title>AVA - {t('pages:coffeeMachine')} </title>
      </Helmet>
          {this.props.location.pathname.split('/')[2] !== undefined && <Post postId={this.props.location.pathname.split('/')[2]} />}
          <div className="tw-flex tw-flex-row tw-justify-start tw-flex-wrap tw-pt-1">
            <Button text={t('addPost')} action={() => {authOverlay() && this.setState({createPostOpened: true})}} />
            {/* AJOUTER AVEC LA PARTIE EMPLOYEUR MACHINE À CAFÉ */}
            {/* <Button text={t('addJobOffer')} action={() => {window.location.href = 'https://employers.alpeconception.com/employers'}} /> */}
            {hashtags.map(h => (
            <button {...this.props} onClick={() => this.setState({loading: true, search: h.hashtag}, this.getPosts(h.hashtag))} className="focus:tw-bg-gray-900 bg-full-gray tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-white tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none ">
              {h.hashtag}
            </button>
            ))}

            <CreatePost opened={this.state.createPostOpened} closeModal={() => {this.setState({createPostOpened: false})}} />
          </div>
          {<button {...this.props} onClick={() => {
            if(notAffiliated()) {
              if(isAuth()) {
                utilsOpenPopup('affiliate');
              } else {
                utilsOpenPopup('noAuthAffiliate');
              }
            } else {
              this.props.history.push('/affiliation')
            }
            }} style={{zIndex: 1000, bottom: '100px'}} className="refer-button tw-text-center tw-bg-aa-yellow tw-fixed tw-right-0 tw-font-bold tw-text-xl tw-px-4 tw-py-3 tw-text-black">
              {this.props.t('affiliation')}
          </button>}
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4, 1200: 6 }}
          >
            <Masonry>
              {this.concated().map(p => {
                return (<PostComponent cartUpdate={(n) => {this.setState({cartSize: n})}} key={p.id} liked={this.getLiked(p)} post={p} types={this.state.types} auto_pinned_ids={this.state.auto_pinned_ids} affiliated={this.state.affiliated} />);
              })}
            </Masonry>
          </ResponsiveMasonry>
          <div className="tw-flex tw-flex-row tw-justify-center">
            {this.state.done == true || posts.length < 40 || posts.length == 0 ? <div className="tw-card bg-434349"><p>{t('thatsAll')}</p></div>: <ReactLoading type="bubbles" color="#f5ff00" />}
          </div>
      </Screen>
    );
  }
}

CoffeeMachine.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ popup }) => {
  return {
    popup: popup.popup,
  };
};

export default connect(mapStateToProps, null)(withTranslation('common')(CoffeeMachine));
