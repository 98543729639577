import { Form } from 'components/forms/Form';
import Panel from 'components/forms/Panel';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import * as Cookies from 'js-cookie';
import { formToFormData, encodeDataToURL, findIndex, getLanguage } from 'utils';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { addErrors } from 'redux/actions';
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';

export class Signup extends Component {

  constructor (props) {
    super(props);
    this.state = {currentPanel: 0, data: [], loading: true};
    this.panels = [
      // [
      //   {
      //     'name': 'introduction',
      //     'type': 'descriptionFirst'
      //   },
      //   {
      //     'name': 'type',
      //     'options': 'type',
      //     'type': 'dropdown'
      //   },
      //   {
      //     'name': 'connections',
      //     'options': 'type',
      //     'type': 'dropdownMultiple'
      //   },
      // ],
      [
        {
          'name': 'full_name',
        },
        {
          'name': 'email',
          'inputType': 'email',
          'required': true
        },
        {
          'name': 'phone_number',
        },
      ],
      {
        'name': 'submit',
        'type': 'submit'
      },
    ];
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.setPath = this.setPath.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit () {
    if(this.props.form?.type == 'MERCHANT' || this.props.form?.type == 'EMPLOYER') {
      var form = Object.assign({}, this.props.form);
      form.lang = getLanguage();
      form.invite = Cookies.get('employer_invite') ? Cookies.get('employer_invite') : Cookies.get('invite');
      window.location.href = process.env.REACT_APP_BUSINESS_URL  + '/subscription-signup?' + encodeDataToURL(form); 
    } else {
      this.setState({ loading: true });
      if(this.props.form) {
        var fd = formToFormData([
          'email', 
          // 'password', 
          // 'password_confirmation', 
          'full_name', 
          'phone_number', 
          // 'postal', 
          // 'city', 
          // 'field_of_work', 
          // 'interests', 
          // 'salary_id', 
          // 'availability', 
          // 'job_type', 
          // 'work_experiences', 
          // 'cv', 
          // 'video_cv', 
          // 'training', 
          'bio', 
          // 'driver', 
          // 'car', 
          // 'work_permit', 
          // 'student_permit', 
          // 'permanence', 
          // 'citizen', 
          'connections',
          'type'
        ], this.props.form);
        fd.append('invite', Cookies.get('employer_invite') ? Cookies.get('employer_invite') : Cookies.get('invite'));
        fd.append('ref', Cookies.get('ref'));
        fd.append('lang', getLanguage());
        instance.post('/candidate' + (this.props.location.search === '?type=buyer' ? '?candidate=false' : ''), fd).then((success) => {
          Cookies.set('auth', success.data.token);
          Cookies.set('email_verified', success.data.email_verified);
          Cookies.set('affiliated', false);
          if (Cookies.get('job_offer')) {
            instance.get('/job-offer-without-email?job_offer=' + Cookies.get('job_offer'));
            Cookies.remove('job_offer');
          }
          this.props.history.push('/password-setup');
          // if (Cookies.get('origin') !== undefined) {
          //   var origin = Cookies.get('origin');
          //   Cookies.remove('origin');
          //   this.props.history.push(origin);
          // } else {
          //   this.props.history.push('/');
          // }
        }, (error) => {
          this.checkErrorsStage(error.response.data.errors);
          this.props.addErrors(error.response.data.errors, 'signup');
        }).then(() => {
          this.setState({ loading: false });
        });
      } else {
        this.setState({ loading: false, currentPanel: 0});
      }  
    }
  }

  checkErrorsStage (errors) {
    var smallest = 999999;
    if (typeof errors === 'object') {
      Object.keys(errors).forEach(error => {
        var index = findIndex(this.panels, error);
        if(index < smallest) {
          smallest = index;
        }
      });
    }
    if(smallest !== 999999) {
      this.setState({currentPanel: smallest});
    }
  }

  componentDidMount () {
    Cookies.set('welcomeSeen', true);
    instance.get('/candidate-data')
      .then((success) => {
        this.setState({
          loading: false,
          data: {
            // interests: success.data.interests,
            // fieldsOfWork: success.data.fieldsOfWork,
            // salary: success.data.salary,
            // availability: success.data.availability,
            // job_type: success.data.job_type,
            // trainings: success.data.trainings,
            type: success.data.type   
          }
        });
      });
  }


  nextSlide () {
    this.setState({currentPanel: this.state.currentPanel + 1});
  }

  previousSlide () {
    this.setState({currentPanel: this.state.currentPanel - 1});
  }

  setPath(path) {
    this.setState({path: path, currentPanel: this.state.currentPanel + 1});

  }

  render () {
    if (this.state.loading && this.state.loading === true) {
      return (
        <div className="tw-w-full tw-h-screen tw-flex tw-flex-row tw-justify-center tw-items-center" style={{ backgroundColor: '#434349' }}>
          <ReactLoading type="bubbles" color="#f5ff00" />
        </div>
      );
    }
    return (
      <Form name="signup" classes="" classesOverwrite={true} onSubmit={(e) => {e.preventDefault()}}>
                <Helmet>
        <title>AVA - {this.props.t('signup')} </title>
      </Helmet>
        <Panel submit={this.handleSubmit} data={this.state.data} setPath={this.setPath} path={this.state.path} nextSlide={this.nextSlide} previousSlide={this.previousSlide} current={this.state.currentPanel} maxSlides={this.panels.length} panel={this.panels[this.state.currentPanel]} type="signup" />
      </Form>
    )  
  }
  
}


const mapStateToProps = ({ input }) => {
  return {
    form: input.signup
  };
};


export default withRouter(connect(mapStateToProps, { addErrors })(withTranslation(['pages'])(Signup)));
