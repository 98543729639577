import { Form } from 'components/forms/Form';
import Panel from 'components/forms/Panel';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import * as Cookies from 'js-cookie';
import { formToFormData, encodeDataToURL, findIndex, getLanguage } from 'utils';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { addErrors, handleChange } from 'redux/actions';
import ReactLoading from 'react-loading';
import { Helmet } from 'react-helmet';
import {BrowserView, MobileView} from 'react-device-detect';
import bg_signup from 'videos/bg-signup.mp4';
import robot from 'img/onboarding/robot.PNG';
import screenshot from 'img/onboarding/screenshot.PNG';
import logo from 'img/icons/logo_yellow.svg';
import home from 'img/icons/home.svg';
import time from 'img/onboarding/time.svg';
import money from 'img/onboarding/money.svg';
import globe from 'img/onboarding/globe.svg';
import grow from 'img/onboarding/grow.svg';
import interview from 'img/onboarding/interview.svg';


export class Onboarding extends Component {

  employerUrl(type = 'EMPLOYER') {
    var base = "https://business.alpeconnexion.com/employers/subscription-signup?type=" + type;
    if(Cookies.get('invite')) {
      base = base + "&invite=" + Cookies.get('invite');
    }
    if(Cookies.get('employer_invite')) {
      base = base + "&employer_invite=" + Cookies.get('employer_invite');
    }
    if(Cookies.get('ref')) {
      base = base + "&ref=" + Cookies.get('ref');
    }
    return base;
  }

  render () {
    Cookies.set('welcomeSeen', true); 
    return (
      <>
        <Helmet>
          <title>AVA - {this.props.t('signup')}</title>
        </Helmet>
      <>
          <BrowserView>
          <div className="bg-gray tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" />
          <video autoPlay muted loop className="tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" style={{maxWidth: 'none'}}>
            <source src={bg_signup} type="video/mp4" />
          </video>
          </BrowserView>
          <MobileView>
            <div className="bg-video tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" />
          </MobileView>
        </>
        <div id="header" class="lg:tw-flex tw-w-full tw-hidden tw-justify-between tw-fixed tw-t-0 tw-py-8 tw-pl-8" style={{zIndex: 100}}>
          <img src={logo} alt="" className="tw-h-24 tw-cursor-pointer" onClick={() => {this.props.history.push('/')}}/>
          <div className="tw-flex tw-justify-between tw-self-start tw-bg-black tw-rounded-tl-full tw-rounded-bl-full tw-px-4 tw-py-3 tw-text-lg tw-text-aa-yellow">
            <span className="tw-mx-4 tw-ml-2 tw-cursor-pointer hover:tw-underline" onClick={() => {
                  window.location.href = this.employerUrl();
                }}>{this.props.t('employer')}</span>
            <span className="tw-mx-4 tw-cursor-pointer hover:tw-underline" onClick={() => {
                  this.props.handleChange('type', 'EMPLOYEE', 'signup');
                  this.props.history.push('/signup/infos');
                }}>{this.props.t('employee')}</span>
            <span className="tw-mx-4 tw-cursor-pointer hover:tw-underline" onClick={() => {
                  window.location.href = this.employerUrl('SELF-EMPLOYED');
                }}>{this.props.t('freelancers')}</span>
            <span className="tw-mx-4 tw-cursor-pointer hover:tw-underline" onClick={() => {
                  this.props.handleChange('type', 'EMPLOYEE', 'signup');
                  Cookies.set('origin', '/affiliation')
                  this.props.history.push('/signup/infos');
                }}>Affiliation</span>
          </div>
        </div>
        <div class="tw-absolute tw-w-full">
          <div class="tw-flex tw-justify-center tw-flex-col lg:tw-hidden tw-mb-16">
            <div class="tw-py-6">
              <img src={logo} alt="" className="tw-mx-auto tw-h-16 tw-cursor-pointer" onClick={() => {this.props.history.push('/')}}/>
            </div>
            <div className="tw-flex tw-flex-wrap tw-justify-center tw-bg-black tw-px-4 tw-py-3 tw-text-lg tw-text-aa-yellow">
            <span className="tw-mx-4 tw-ml-2 tw-cursor-pointer hover:tw-underline" onClick={() => {
                  window.location.href = this.employerUrl();
                }}>{this.props.t('employer')}</span>
            <span className="tw-mx-4 tw-cursor-pointer hover:tw-underline" onClick={() => {
                  this.props.handleChange('type', 'EMPLOYEE', 'signup');
                  this.props.history.push('/signup/infos');
                }}>{this.props.t('employee')}</span>
            <span className="tw-mx-4 tw-cursor-pointer hover:tw-underline" onClick={() => {
                  window.location.href = this.employerUrl('SELF-EMPLOYED');
                }}>{this.props.t('freelancers')}</span>
            <span className="tw-mx-4 tw-cursor-pointer hover:tw-underline" onClick={() => {
                  this.props.handleChange('type', 'EMPLOYEE', 'signup');
                  Cookies.set('origin', '/affiliation')
                  this.props.history.push('/signup/infos');
                }}>Affiliation</span>
          </div>


          </div>
          {/* FIRST */}
          <div class="lg:tw-h-screen tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-16 ">
            <div class="tw-hidden lg:tw-flex tw-justify-center tw-items-end">
              <img src={robot} alt="" />
            </div>
            <div class="tw-text-right tw-flex tw-flex-col tw-justify-center tw-items-end tw-pr-16">
              <p class="tw-text-6xl tw-text-aa-yellow tw-font-bold tw-leading-none">{this.props.t('virtualAssistant')}</p>
              <p className="tw-text-2xl tw-text-white tw-mt-2">{this.props.t('connectBusiness')}</p>
              <div class="tw-text-3xl tw-bg-aa-yellow tw-text-black tw-font-bold tw-px-8 tw-py-4 tw-mt-8 tw-cursor-pointer" onClick={() => {
                const anchor = document.getElementById('types');
                if(anchor) {
                    anchor.scrollIntoView();
                }              
              }}>
              {this.props.t('startNow')} →
              </div>
            </div>
            <div class="lg:tw-hidden tw-flex tw-justify-center tw-items-end">
              <img src={robot} alt="" />
            </div>

          </div>
            {/* SECOND */}
          <div class="tw-py-64 tw-grid tw-grid-cols-1 tw-gap-y-16 lg:tw-grid-cols-2">
            <div class="tw-flex tw-flex-col tw-items-center tw-px-6 sm:tw-px-10 md:tw-px-24">
              <p class="tw-text-4xl tw-text-white tw-text-center tw-font-bold tw-leading-none tw-w-full">{this.props.t('findJob')}</p>
              <p className="tw-w-full tw-text-2xl tw-text-aa-yellow tw-mt-2">{this.props.t('findJobP1')}</p>
              <p className="tw-w-full tw-text-2xl tw-text-aa-yellow tw-mt-2">{this.props.t('findJobP2')}</p>
              <div class="tw-self-end tw-text-3xl tw-bg-aa-yellow tw-text-black tw-font-bold tw-px-8 tw-py-4 tw-mt-8 tw-cursor-pointer" onClick={() => {
                  this.props.handleChange('type', 'EMPLOYEE', 'signup');
                  this.props.history.push('/signup/infos');
                }}>
              {this.props.t('chatNow')} →
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-items-center tw-px-6 md:tw-px-24">
              <p class="tw-w-full tw-text-4xl tw-text-white tw-text-center tw-font-bold tw-leading-none tw-w-full">{this.props.t('findCustomers')}</p>
              <p className="tw-w-full tw-text-2xl tw-text-aa-yellow tw-mt-2">{this.props.t('findCustomersP1')}</p>
              <p className="tw-w-full tw-text-2xl tw-text-aa-yellow tw-mt-2">{this.props.t('findCustomersP2')}</p>

              <div class="tw-self-end tw-text-3xl tw-bg-aa-yellow tw-text-black tw-font-bold tw-px-8 tw-py-4 tw-mt-8 tw-cursor-pointer" onClick={() => {
                  window.location.href = this.employerUrl('SELF-EMPLOYED');
              }}>
              {this.props.t('letsBegin')} →
              </div>
            </div>
          </div>
            {/* THIRD */}

          <div class="lg:tw-h-screen tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-16">
            <div class="tw-text-right tw-flex tw-flex-col tw-justify-center tw-items-end tw-px-10">
              <p class="tw-text-4xl tw-text-aa-yellow tw-font-bold tw-leading-none">{this.props.t('assistResearch')}</p>
              <p className="tw-text-2xl tw-text-aa-yellow tw-mt-2">{this.props.t('assistResearchP1')}</p>
              <p className="tw-text-2xl tw-text-aa-yellow tw-mt-2">{this.props.t('assistResearchP2')}</p>
              <p className="tw-text-2xl tw-text-aa-yellow tw-mt-2">{this.props.t('assistResearchP3')}</p>

              <div class="tw-text-3xl tw-bg-aa-yellow tw-text-black tw-font-bold tw-px-8 tw-py-4 tw-mt-8 cursor-pointer" onClick={() => {
                window.location.href = this.employerUrl();
              }}>
              {this.props.t('letsBegin')} →
              </div>
            </div>
            <div class="tw-flex tw-justify-end tw-items-center">
              <img src={screenshot} alt="" />
            </div>
          </div>

            {/* FOURTH */}

          <div class="bg-mountain tw-py-32 tw-pt-0" id="types">
            <div class="tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-w-full tw-px-16 tw-py-16 bg-black-50">
              <div class="mountain_element">
                <img src={time} alt="" />
                <span>{this.props.t('gainTime')}</span>
              </div>
              <div class="mountain_element">
                <img src={money} alt="" />
                <span>{this.props.t('expertise')}</span>
              </div>
              <div class="mountain_element">
                <img src={globe} alt="" />
                <span>{this.props.t('opportunitiesWorld')}</span>
              </div>
              <div class="mountain_element">
                <img src={grow} alt="" />
                <span>{this.props.t('increaseResources')}</span>
              </div>
              <div class="mountain_element">
                <img src={interview} alt="" />
                <span>{this.props.t('fastInterview')}</span>
              </div>
            </div>
            <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-16 tw-mt-64 tw-mb-32 tw-px-16">
              <div class="tw-text-center">
                <p class="tw-text-4xl tw-text-white tw-underline tw-cursor-pointer hover:tw-no-underline" onClick={() => {
                  this.props.handleChange('type', 'EMPLOYEE', 'signup');
                  this.props.history.push('/signup/infos');
                }}>{this.props.t('jobOffer')}</p>
                <p class="tw-text-2xl tw-text-aa-yellow">{this.props.t('jobOfferDesc')}</p>
              </div>
              <div class="tw-text-center">
                <p class="tw-text-4xl tw-text-white tw-underline tw-cursor-pointer hover:tw-no-underline" onClick={() => {
                  window.location.href = this.employerUrl('SELF-EMPLOYED');
                }}>{this.props.t('freelancer')}</p>
                <p class="tw-text-2xl tw-text-aa-yellow">{this.props.t('freelancerDesc')}</p>
              </div>
              <div class="tw-text-center">
                <p class="tw-text-4xl tw-text-white tw-underline tw-cursor-pointer hover:tw-no-underline" onClick={() => {
                  window.location.href = this.employerUrl();
                }}>{this.props.t('businessOnb')}</p>
                <p class="tw-text-2xl tw-text-aa-yellow">{this.props.t('businessOnbDesc')}</p>
              </div>
              <div class="tw-text-center">
                <p class="tw-text-4xl tw-text-white tw-underline tw-cursor-pointer hover:tw-no-underline" onClick={() => {
                  this.props.handleChange('type', 'EMPLOYEE', 'signup');
                  Cookies.set('origin', '/affiliation')
                  this.props.history.push('/signup/infos');
                }}>{this.props.t('affiliationOnb')}</p>
                <p class="tw-text-2xl tw-text-aa-yellow">{this.props.t('affiliationOnbDesc')}</p>
              </div>
            </div>
          </div>

        </div>

      </>

    )  
  }
  
}


const mapStateToProps = ({ input }) => {
  return {
    form: input.signup
  };
};


export default withRouter(connect(mapStateToProps, { handleChange })(withTranslation(['common'])(Onboarding)));
