import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import instance from 'axiosInstance';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import profile from 'img/placeholder/profile.svg';
import PostMedias from 'components/social/PostMedias';
import heartEmpty from 'img/icons/heart-empty.svg';
import heartFill from 'img/icons/heart-fill.svg';
import ReactLoading from 'react-loading';
import Form from 'components/forms/Form';
import { connect } from 'react-redux';
import { addErrors, handleChange, listAdd, listDescAdd, clearForm } from 'redux/actions';
import TextareaInput from 'components/forms/TextareaInput';
import Submit from 'components/forms/Submit';
import { authOverlay, formToFormData } from './../../utils';
import close from 'img/icons/close.svg';
import share from 'img/icons/share.svg';
import Linkify from 'react-linkify';

export class Post extends Component {
  constructor () {
    super();
    this.state = { loading: true, post: {}, comments: [], amountLiked: null, liked: null, likeLoading: false, deleting: false };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    this.getPostWithComments();
  }

  getPostWithComments () {
    instance.get('/posts/' + this.props.postId).then(s => {
      this.setState({ loading: false, post: s.data.post, comments: s.data.post.comments, amountLiked: s.data.post.likes_count, liked: s.data.liked });
    }, e => {
      if (this.props.profile) {
        this.props.history.push('/profile/' + this.props.profile);
      } else {
        this.props.history.push('/');
      }
    });
  }

  getProfilePic (obj) {
    if (!obj.candidate.profile_pic) {
      return profile;
    }
    return (process.env.REACT_APP_STORAGE_URL + obj.candidate.profile_pic).replace(/([^:]\/)\/+/g, '$1');
  }

  getLiked () {
    return this.state.liked === true ? <img src={heartFill} style={{ height: 24, width: 24 }} /> : <img src={heartEmpty} style={{ height: 24, width: 24 }} />;
  }

  addLike () {
    const { post } = this.state;
    this.setState(state => {
      var liked = !state.liked;
      var amountLiked = state.liked === true ? parseInt(state.amountLiked) - 1 : parseInt(state.amountLiked) + 1;
      var likeLoading = true;
      return { liked, amountLiked, likeLoading };
    });
    instance.get(`/posts/${post.id}/like`).then(s => {
      this.setState({ likeLoading: false });
    });
  }

  handleSubmit (e) {
    e.preventDefault();
    this.setState({ loading: true });
    var fd = formToFormData(['comment'], this.props.form);
    instance.post('/posts/' + this.state.post.id + '/comment', fd).then((success) => {
      this.props.clearForm('add-comment');
      window.location.reload();
    }, (error) => {
      this.props.addErrors(error.response.data.errors, 'create-post');
    }).then(() => {
      this.setState({ loading: false });
    });
  }

  deleteComment (id) {
    if (window.confirm(this.props.t('delete') + ' ?')) {
      this.setState({ loading: true });
      instance('/comments/' + id + '/delete').then(s => {
        window.location.reload();
      });
    }
  }

  deletePost () {
    if (window.confirm(this.props.t('delete') + ' ?')) {
      this.setState({ deleting: true });
      instance('/posts/' + this.props.postId + '/delete').then(s => {
        this.props.history.push('/profile/' + this.props.profile);
        window.location.reload();
      });
    }
  }

  share () {
    if (navigator.share) {
      navigator.share({
        url: 'https://alpeconception.com/posts/' + this.props.postId
      });
    } else {
      var dummy = document.createElement('textarea');
      document.body.appendChild(dummy);
      dummy.value = 'https://alpeconception.com/posts/' + this.props.postId;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      alert(this.props.t('copiedLink'));
    }
  }

  render () {
    const { post } = this.state;
    return (
      <div onClick={(e) => {
        if (e.target === e.currentTarget) {
          if (this.props.profile) {
            this.props.history.push('/profile/' + this.props.profile);
          } else {
            this.props.history.push('/');
          }
        }
      }}style={{ backgroundColor: 'rgba(0, 0, 0, 0.65)', position: 'absolute', zIndex: 100 }} className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-inset-0">
        {Object.keys(post).length !== 0
          ? <div style={{ minHeight: '80vh', maxHeight: '80vh', overflowY: 'auto' }} className="col-lg-6 tw-mx-auto tw-pb-2">
            {this.state.comments.map(comment => (
              <div key={comment.id} style={{ background: '#434349', borderRadius: 0 }} className="tw-card tw-mb-2">
                <div className="tw-flex tw-flex-row tw-items-center tw-text-white">
                  <div style={{ height: 32, width: 32 }}>
                    <img src={this.getProfilePic(comment)} className="tw-h-full tw-w-full tw-object-cover " />
                  </div>
                  <div className="tw-ml-2  tw-flex tw-flex-col tw-justify-center">
                    <span className="tw-underline hover:tw-no-underline tw-cursor-pointer" onClick={() => { this.props.history.push('/profile/' + comment.candidate.id); }} >{comment.candidate.first_name + ' ' + comment.candidate.last_name}</span>
                    {post.candidate.bio && <span className="tw-text-xs">{(post.candidate.bio)}</span>}
                    <span className="tw-text-xs">{(comment.created_at.substr(0, 10))}</span>
                  </div>

                </div>
                <p className="tw-my-2 tw-whitespace-pre-line text-with-links"><Linkify>{comment.comment}</Linkify></p>
                {comment.yours === true &&
              <div className="tw-flex tw-flex-row tw-justify-end">
                <button onClick={() => { this.deleteComment(comment.id); }} className="focus:tw-bg-red-700 tw-bg-red-500 tw-px-3 tw-py-2 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer tw-flex tw-flex-row focus:tw-outline-none ">
                  {this.props.t('delete')}
                </button>
              </div>}
              </div>
            ))}
            <div className="tw-card tw-mb-2" style={{ background: '#434349', borderRadius: 0 }}>
              <Form onSubmit={(e) => {
                (authOverlay() && this.handleSubmit(e));
              } } name="add-comment">
                <TextareaInput name="comment" />
                <div className="tw-flex tw-flex-row tw-justify-end">
                  <Submit />
                </div>
              </Form>
            </div>
            <div className="bg-gray" style={{ background: '#434349' }}>
              <PostMedias post={post} images={post.images} />
              <div className="tw-pt-4 tw-px-4 tw-flex tw-flex-row tw-justify-between">
                <div className=" tw-flex tw-flex-row tw-items-center tw-text-white">
                  <div style={{ height: 48, width: 48 }}>
                    <img src={this.getProfilePic(post)} className="tw-h-full tw-w-full tw-object-cover " />
                  </div>
                  <div className="tw-ml-2  tw-flex tw-flex-col tw-justify-center">
                    <span className="tw-underline hover:tw-no-underline tw-cursor-pointer" onClick={() => { this.props.history.push('/profile/' + post.candidate.id); }} >{post.candidate.first_name + ' ' + post.candidate.last_name}</span>
                    {post.candidate.bio && <span className="tw-text-xs">{(post.candidate.bio)}</span>}
                    <span className="tw-text-xs">{(post.date)}</span>
                  </div>
                </div>
                <div className="tw-flex tw-flex-row tw-items-center">
                  {this.props.mine === true &&
                <>
                  <button onClick={() => { this.props.history.push('/posts/' + post.id + '/update'); }} className="focus:tw-bg-blue-700 tw-bg-blue-500  tw-px-3 tw-py-3 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer focus:tw-outline-none focus:tw-shadow-outline">
                    {this.props.t('edit')}
                  </button>
                  {this.state.deleting === true ? <ReactLoading type="bubbles" color="#f5ff00" height={34} width={34} />
                    : <button onClick={() => { this.deletePost(); }} className="focus:tw-bg-red-700 tw-bg-red-500  tw-px-3 tw-py-3 tw-font-bold  tw-text-sm tw-m-1 tw-self-end tw-leading-none tw-min-w-32 tw-text-black tw-cursor-pointer focus:tw-outline-none focus:tw-shadow-outline">
                      {this.props.t('delete')}
                    </button>}
                </>}
                </div>
              </div>
              <p className="tw-px-4 tw-my-2 tw-whitespace-pre-line text-with-links"><Linkify>{post.text}</Linkify></p>
              <div className="tw-px-4 tw-pb-4 tw-flex tw-flex-row tw-space-x-2">
                <div className="tw-flex tw-flex-row tw-cursor-pointer" onClick={() => { authOverlay() && this.addLike(); }}>
                  {this.state.likeLoading == true ? <ReactLoading type="bubbles" color="#f5ff00" height={24} width={24} /> : <>
                    <span className="tw-mr-2">{this.state.amountLiked}</span>
                    {this.getLiked()}
                  </>}
                </div>
                <img onClick={() => { this.share(); }} src={share} style={{ cursor: 'pointer', height: 24, width: 24 }} />
              </div>
            </div>
            
            
          </div> : <div style={{ height: 100, width: 100, display: 'flex', flexDirection: 'colum', justifyContent: 'center', alignItems: 'center' }}><ReactLoading type="bubbles" color="#f5ff00" /></div>}
      </div>
    );
  }
}

Post.defaultProps = {
  form: {}
};

const mapStateToProps = ({ input }) => {
  return {
    form: input['add-comment']
  };
};

export default withRouter(connect(mapStateToProps, { addErrors, handleChange, listAdd, listDescAdd, clearForm })(withTranslation('common')(Post)));
