import React, { Component } from 'react';
import messages from 'img/icons/phone.svg';
import messagesNotif from 'img/icons/phoneNotif.svg';
import settings from 'img/icons/settings.svg';
import back from 'img/icons/back.svg';
import home from 'img/icons/home.svg';
import logout from 'img/icons/logout.svg';
import menu from 'img/icons/menu.svg';
import shop from 'img/icons/shop.svg';
import profile from 'img/icons/profile.svg';
import close from 'img/icons/close.svg';
import cart from 'img/icons/cart.svg';
import bell from 'img/icons/bell.svg';
import light from 'img/icons/light.svg';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import * as Cookies from 'js-cookie';
import { withTranslation } from 'react-i18next';
import { isAuth, getAmountInCart, utilsOpenPopup } from 'utils';
import instance from 'axiosInstance';
import Notifications from 'components/social/Notifications';
import bg_signup from 'videos/bg-signup.mp4';
import EmployerPrompt from 'components/common/EmployerPrompt';
import {BrowserView, MobileView} from 'react-device-detect';
import SetupPopup from 'components/profile/SetupPopup';
import { connect } from 'react-redux';

export class Screen extends Component {
  constructor () {
    super();
    this.getBackground = this.getBackground.bind(this);
    this.getNotifs = this.getNotifs.bind(this);
    this.changeAccount = this.changeAccount.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = { seen: false, messaging: 0, notifsCount: 0, posts: [], notifications: false, employerPrompt: false, videoDone: true, cvDone: true };
  }

  componentDidMount () {
    if (isAuth()) {
      this.getNotifs();
      setInterval(() => { this.getNotifs(); }, 120000);
    }
  }

  getNotifs () {
    instance.get('/notifs').then(s => {
      var notifsCount = s.data.posts.length + (s.data.videoDone == false ? 1 : 0) + (s.data.cvDone == false ? 1 : 0);
      this.setState({ messaging: s.data.messaging, posts: s.data.posts, videoDone: s.data.videoDone, cvDone: s.data.cvDone, notifsCount });
    });
  }

  getBackground () {
    if(this.props.type == 'video') {
      return 'tw-fixed tw-overflow-y-auto';
    }
    var type = this.props.type !== undefined ? this.props.type : 'bureaux';
    return 'bg-' + type;
  }

  getPadding () {
    return this.props.padding === false ? ' ' : this.props.paddingTop === false ? ' pb-3 ' : ' py-3 ';
  }

  getEmployerHeader() {
    if(Cookies.get('employer')) {
      return (<div className="tw-h-8 tw-absolute tw-t-0 tw-bg-aa-yellow tw-text-black tw-flex tw-flex-col tw-justify-center tw-w-full" style={{zIndex: 2}}>
        <p onClick={this.changeAccount} className="tw-px-2">{this.props.t('common:connectedAs', {name: Cookies.get('current')})}. <span className="hover:tw-no-underline tw-underline tw-cursor-pointer">{this.props.t('common:switchTo', {name: Cookies.get('switchee')})}</span></p>
    </div>);
    }
    
  }

  employerHeaderAdjustment() {
    if(Cookies.get('employer')) {
      return 'tw-pt-8';
    }
  }

  changeAccount() {
    var switchee = Cookies.get('switchee');
    var current = Cookies.get('current');
    if(current != Cookies.get('employer')) {
      this.setState({employerPrompt: true})
    } else {
      Cookies.set('switchee', current);
      Cookies.set('current', switchee);
      window.location.reload();    
    }
  }

  handleClick() {
    if((this.state.videoDone == false || this.state.cvDone == false) && Cookies.get('auth') && !Cookies.get('onboardingSeen') && this.props.location.pathname != '/password-setup') {
      this.props.history.push('/');      
      utilsOpenPopup('onboarding');
      Cookies.set('onboardingSeen', true)
    }
  }

  getPopup() {
    if(isAuth()) {
      switch(this.props.popup) {
        case 'onboarding':
          return <SetupPopup onlyShow={true} key="onboarding" type="onboarding" />;
        case 'explanations':
          return <SetupPopup onlyShow={true} key="explanations" type="explanations" />;  
        case 'affiliate':
          return <SetupPopup key="affiliate" type="affiliate" />;
  
      }
    } else {
      switch(this.props.popup) {
        case 'noAuthAffiliate':
          return <SetupPopup onlyShow={true} key="noAuthAffiliate" type="noAuthAffiliate" />;
      }
    }
    return null;
  }

  render () {
    if (this.props.loading && this.props.loading === true) {
      return (
        <div className="tw-w-full tw-h-screen tw-flex tw-flex-row tw-justify-center tw-items-center" style={{ backgroundColor: '#434349' }}>
          <ReactLoading type="bubbles" color="#f5ff00" />
        </div>
      );
    }
    return (
      <>
      {this.getEmployerHeader()}
        <>
          <BrowserView>
          <div className="bg-gray tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" />
          <video autoPlay muted loop className="tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" style={{maxWidth: 'none'}}>
            <source src={bg_signup} type="video/mp4" />
          </video>
          </BrowserView>
          <MobileView>
          <div className="bg-video tw-fixed tw-inset-0 tw-min-h-full tw-min-w-full" />
          </MobileView>

        </>
        {this.getPopup()}

      <div className={'tw-h-screen tw-w-screen tw-grid tw-grid-rows-layout ' + this.employerHeaderAdjustment()} onClick={this.handleClick}>
        <Notifications videoDone={this.state.videoDone} cvDone={this.state.cvDone} opened={this.state.notifications} posts={this.state.posts} updateNotifs={this.getNotifs} handle={(s) => {
          this.setState({ notifications: s });
        }} />
        <EmployerPrompt display={this.state.employerPrompt} />
        <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-font-display" style={{ backgroundColor: '#434349', zIndex: this.props.overall === true ? 999 : 1 }}>
          <div className="tw-flex tw-w-1/8 md:tw-w-1/3 tw-justify-start md:tw-space-x-4 md:tw-pl-8 tw-pl-4 tw-flex-row">
            <img src={back} onClick={() => {
              this.props.history.goBack();
            }} className="tw-hidden md:tw-block tw-h-6 tw-cursor-pointer" />
            <img src={home} onClick={() => {
              this.props.history.push('/');
            }} className="tw-h-6 tw-cursor-pointer" />
          </div>

          {this.props.titleComp ? this.props.titleComp
            : <h1 className="tw-w-1/3 tw-text-center tw-pl-8 md:tw-px-0 tw-whitespace-no-wrap tw-font-bold tw-text-xl tw-text-white">
              {this.props.title}
            </h1>}

          <div className="tw-hidden md:tw-flex tw-w-1/3 tw-justify-end tw-space-x-4 tw-pr-8 tw-flex-row">
          {isAuth() && <img src={light} className="tw-h-6 tw-cursor-pointer tw-flex-shrink-0" onClick={() => {
               this.props.history.push('/seen-job-offers');
            }}/>}

            {isAuth() &&
            <div className="tw-relative tw-flex-shrink-0">
              <img src={bell} className="tw-h-6 tw-cursor-pointer tw-flex-shrink-0" onClick={() => {
                this.setState({ notifications: true });
              }}/>
              {this.state.notifsCount > 0 &&
              <span style={{ borderRadius: '50%', padding: '0px 6px', top: -6, right: -6 }} className="tw-text-sm tw-absolute aa-badge tw-bg-aa-yellow tw-text-black">{this.state.notifsCount}</span>
              }
            </div>}
            {isAuth() &&
            <div className="tw-relative tw-flex-shrink-0">
              <img src={messages} className="tw-h-6 tw-cursor-pointer tw-flex-shrink-0" onClick={() => {
                this.props.history.push('/messaging');
              }}/>
              {this.state.messaging > 0 &&
              <span style={{ borderRadius: '50%', padding: '0px 6px', top: -6, right: -6 }} className="tw-text-sm tw-absolute aa-badge tw-bg-aa-yellow tw-text-black">{this.state.messaging}</span>
              }
            </div>}
            {isAuth() && <img src={profile} className="tw-h-6 tw-cursor-pointer tw-flex-shrink-0" onClick={() => {
              this.props.history.push('/dashboard');
            }}/>}
            <img src={shop} className="tw-h-6 tw-cursor-pointer tw-flex-shrink-0" onClick={() => {
              this.props.history.push('/shop');
            }}/>
            <div className="tw-relative tw-flex-shrink-0">
              
              
              {this.props.showCart && <><img src={cart} onClick={() => {
                this.props.history.push('/cart');
              }} className="tw-h-6 tw-cursor-pointer tw-flex-shrink-0" />
              {((this.props.cartSize && this.props.cartSize > 0) || getAmountInCart() > 0) &&
              <span style={{ borderRadius: '50%', padding: '0px 6px', top: -6, right: -6 }} className="tw-text-sm tw-absolute aa-badge tw-bg-aa-yellow tw-text-black">{(this.props.cartSize && this.props.cartSize > 0) ? this.props.cartSize : getAmountInCart()}</span>
              }</>}
            </div>
            {Cookies.get('auth') && <img src={logout} className="tw-h-6 tw-cursor-pointer tw-flex-shrink-0" onClick={() => {
              this.props.history.push('/logout-page');
            }}/>}

          </div>

          <img src={menu} className="tw-block md:tw-hidden tw-h-6 tw-cursor-pointer tw-pr-8 tw-cursor-pointer tw-flex-shrink-0" onClick={() => {
            this.setState({ menuOpened: true });
          }}/>

          {this.state.menuOpened && (
            <div className="tw-h-screen tw-space-y-4 tw-z-50 tw-w-screen tw-absolute tw-bg-black tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-flex tw-flex-col tw-justify-center tw-items-center">
              <img style={{ top: '1.25rem', right: '2rem' }} src={close} className="tw-block md:tw-hidden tw-h-10 tw-cursor-pointer tw-right-8 tw-absolute tw-top-8" onClick={() => {
                this.setState({ menuOpened: false });
              }}/>
              <p onClick={() => { this.props.history.push('/'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('home')}
              </p>
              <p onClick={() => { this.props.history.push('/seen-job-offers'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('common:oldJobOffers')}
              </p>
              <p onClick={() => { this.props.history.push('/job-offers'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('jobs')}
              </p>
              {isAuth() && <p onClick={() => {
                this.setState({ notifications: true });
              }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('common:notifications')}
              </p>}
              {isAuth() && <p onClick={() => { this.props.history.push('/dashboard'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('profile')}
              </p>}
              {isAuth() && <p onClick={() => { this.props.history.push('/messaging'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('messages')}
              </p>}
              <p onClick={() => { this.props.history.push('/shop'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('trainings')}
              </p>
              {this.props.showCart && <p onClick={() => { this.props.history.push('/cart'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('purchases')}
              </p>}

              {isAuth() && <p onClick={() => { this.props.history.push('/logout-page'); }} className="tw-text-white tw-text-xl hover:tw-underline">
                {this.props.t('logout')}
              </p>}
            </div>)}

        </div>
        <div onScroll={this.props.handleScroll || function () {}} style={{zIndex: 50}} className={'w-full tw-overflow-y-auto ' + this.getPadding() + this.props.containerClasses}>
          {this.props.children}
        </div>

      </div>
      </>
    );
  }
}

Screen.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  containerClasses: PropTypes.string,
  loading: PropTypes.bool
};

const mapStateToProps = ({ popup }) => {
  return {
    popup: popup.popup,
  };
};


export default connect(mapStateToProps, null)(withRouter(withTranslation(['pages', 'common'])(Screen)));
