import CompanyBox from 'components/jobOffers/CompanyBox';
import JobBox from 'components/jobOffers/JobBox';
import React, { Component } from 'react';
import Screen from 'components/templates/Screen';
import { withTranslation } from 'react-i18next';
import instance from 'axiosInstance';
import celebrating from 'img/celebrating.svg';
import Jobbox from './Jobbox';
import * as Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import OldJobOffers from 'components/jobOffers/OldJobOffers';
import { utilsOpenPopup } from 'utils';
import logo from 'img/icons/home.svg';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PostComponent from 'components/social/PostComponent';

export class SeenJobOffers extends Component {
  constructor () {
    super();
    this.state = { loading: true, jobs: [], posts: [], types: [], componentsLoading: false };
  }

  componentDidMount () {
    instance.get('/old-job-offers').then((s) => {
      this.setState({ loading: false, jobs: s.data.jobs, posts: s.data.posts, types: s.data.types });
    });
  }


  render () {
    return (
      <Screen loading={this.state.loading} type="video" title={this.props.t('history')} padding={false}>
        <h2 class="tw-text-center tw-text-white tw-text-2xl tw-font-bold tw-mt-8">{ this.props.t('oldJobOffers')}</h2>
        <div className="container">
          <div className="row">
            {this.state.jobs.map(job => (
              <div className="bg-full-gray tw-grid tw-grid-cols-12 tw-text-gray-300 tw-mb-8 tw-w-full">
                <img src={ (job?.image?.path) ? (process.env.REACT_APP_STORAGE_URL + job.image.path) : logo } className="tw-col-span-2 tw-p-8 tw-cursor-pointer" onClick={() => this.props.history.push('/job-offer/' + job.id)} />
                <div className="tw-col-span-10 tw-flex tw-flex-col tw-justify-center">
                  <h2 className="tw-text-xl tw-font-bold tw-cursor-pointer  hover:tw-text-white" onClick={() => this.props.history.push('/job-offer/' + job.id)}>{job.title} - {job.employer.name }</h2>
                  <p className="tw-mt-2">{job.description.length > 400 ? job.description.substring(0,400) + "..." : job.description}</p>
                  </div>
              </div>
            ))}
          </div>
        </div>
        <h2 class="tw-text-center tw-text-white tw-text-2xl tw-font-bold tw-mt-4 tw-mb-8">{ this.props.t('publicationsLiked')}</h2>
        <div className="container">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1200: 4 }}
          >
            <Masonry>
              {this.state.posts.map(p => {
                return (<PostComponent cartUpdate={() => {}} key={p.id} liked={true} post={p} types={this.state.types} />);
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      </Screen>
    );
  }
}

export default withTranslation('common')(withRouter(SeenJobOffers));
